/** Config store */
import type {
    ActionContext,
    ActionTree,
    GetterTree,
    Module,
    MutationTree,
} from 'vuex';

import type { RootState } from '.';

import type SelectedProductInterface from '@/interfaces/POSSelectedProductInterface';
import type SelectedAdditionalProductInterface from '@/interfaces/POSSelectedAdditionalProductInterface';
import type POSSelectedMemberInterface from '@/interfaces/POSSelectedMemberInterface';
import type SelectedDeliveryInterface from '@/interfaces/POSSelectedDeliveryInterface';
import type PaymentDataInterface from '@/interfaces/POSPaymentDataInterface';
import type EmptyObject from '@/interfaces/EmptyObjectInterface';

export interface DataState {
    posProductList: Array<SelectedProductInterface>;
    posAdditionalProductList: Array<SelectedAdditionalProductInterface>;
    posOrderTotalAdditionalProductList: Array<SelectedAdditionalProductInterface>;
    posMemberData: POSSelectedMemberInterface | EmptyObject;
    posDeliveryData: SelectedDeliveryInterface,
    posPaymentData: PaymentDataInterface | EmptyObject;
    posOpenEditDeliveryDialog: boolean
    posSelectPaymentMethod: string;
    posEditDeliveryDialogFinish: boolean;
    scanProductBarcodeResult: any;
    scanUserBarcodeResult: any;
    scanOrderTotalAdditionalProductBarcodeResult: any;
}

const state: DataState = {
    posProductList: [],
    posAdditionalProductList: [],
    posOrderTotalAdditionalProductList: [],
    posMemberData: {},
    posDeliveryData: {
        method: '',
        name: '',
        phone: '',
        address: '',
        remark: '',
        selfPickupAddress: '',
        district: '',
        deliveryData: {
            charge: 0,
            free_delivery_amount: 0,
            remark: ''
        }
    },
    posPaymentData: {},
    posOpenEditDeliveryDialog: false,
    posSelectPaymentMethod: '',
    posEditDeliveryDialogFinish: false,
    scanProductBarcodeResult: {},
    scanUserBarcodeResult: {},
    scanOrderTotalAdditionalProductBarcodeResult: {}
}


const getters: GetterTree<DataState, RootState> = {
    posProductList: (s): Array<SelectedProductInterface> => s.posProductList,
    posAdditionalProductList: (s): Array<SelectedAdditionalProductInterface> => s.posAdditionalProductList,
    posOrderTotalAdditionalProductList: (s): Array<SelectedAdditionalProductInterface> => s.posOrderTotalAdditionalProductList,
    posMemberData: (s): POSSelectedMemberInterface | EmptyObject => s.posMemberData,
    posDeliveryData: (s): SelectedDeliveryInterface => s.posDeliveryData,
    posPaymentData: (s): PaymentDataInterface | EmptyObject => s.posPaymentData,
    posOpenEditDeliveryDialog: (s): boolean => s.posOpenEditDeliveryDialog,
    posSelectPaymentMethod: (s): string => s.posSelectPaymentMethod,
    posEditDeliveryDialogFinish: (s): boolean => s.posEditDeliveryDialogFinish,
    scanProductBarcodeResult: (s): any => s.scanProductBarcodeResult,
    scanUserBarcodeResult: (s): any => s.scanUserBarcodeResult,
    scanOrderTotalAdditionalProductBarcodeResult: (s): any => s.scanOrderTotalAdditionalProductBarcodeResult,

}

const mutations: MutationTree<DataState> = {
    updatePosProductList(s, data: Array<SelectedProductInterface>) {
        s.posProductList = data;
    },

    updatePosAdditionalProductList(s, data: Array<SelectedAdditionalProductInterface>) {
        s.posAdditionalProductList = data;
    },

    updatePosOrderTotalAdditionalProductList(s, data: Array<SelectedAdditionalProductInterface>) {
        s.posOrderTotalAdditionalProductList = data;
    },

    updatePosMemberData(s, data: POSSelectedMemberInterface | EmptyObject) {
        s.posMemberData = data;
    },

    updatePosDeliveryData(s, data: SelectedDeliveryInterface) {
        s.posDeliveryData = data;
    },

    updatePosPaymentData(s, data: SelectedDeliveryInterface) {
        s.posPaymentData = data;
    },

    updatePosOpenEditDeliveryDialog(s, data: boolean) {
        s.posOpenEditDeliveryDialog = data;
    },

    updatePosSelectPaymentMethod(s, data: string) {
        s.posSelectPaymentMethod = data;
    },

    updatePosEditDeliveryDialogFinish(s, data: boolean) {
        s.posEditDeliveryDialogFinish = data;
    },


    updateScanProductBarcodeResult(s, data: any) {
        s.scanProductBarcodeResult = data;
    },

    updateScanUserBarcodeResult(s, data: any) {
        s.scanUserBarcodeResult = data;
    },

    updateScanOrderTotalAdditionalProductBarcodeResult(s, data: any) {
        s.scanOrderTotalAdditionalProductBarcodeResult = data;
    }
}

const actions: ActionTree<DataState, RootState> = {
    setPosProductList(context: ActionContext<DataState, RootState>, data: Array<SelectedProductInterface>) {
        context.commit('updatePosProductList', data);
    },

    setPosAdditionalProductList(context: ActionContext<DataState, RootState>, data: Array<SelectedAdditionalProductInterface>) {
        context.commit('updatePosAdditionalProductList', data);
    },

    setPosOrderTotalAdditionalProductList(context: ActionContext<DataState, RootState>, data: Array<SelectedAdditionalProductInterface>) {
        context.commit('updatePosOrderTotalAdditionalProductList', data);
    },

    setPosMemberData(context: ActionContext<DataState, RootState>, data: POSSelectedMemberInterface | EmptyObject) {
        context.commit('updatePosMemberData', data);
    },

    setPosDeliveryData(context: ActionContext<DataState, RootState>, data: SelectedDeliveryInterface) {
        context.commit('updatePosDeliveryData', data);
    },

    setPosPaymentData(context: ActionContext<DataState, RootState>, data: PaymentDataInterface | EmptyObject) {
        context.commit('updatePosPaymentData', data);
    },

    setPosOpenEditDeliveryDialog(context: ActionContext<DataState, RootState>, data: boolean) {
        context.commit('updatePosOpenEditDeliveryDialog', data);
    },

    setPosSelectPaymentMethod(context: ActionContext<DataState, RootState>, data: string) {
        context.commit('updatePosSelectPaymentMethod', data)
    },

    setPosEditDeliveryDialogFinish(context: ActionContext<DataState, RootState>, data: boolean) {
        context.commit('updatePosEditDeliveryDialogFinish', data)
    },

    setScanProductBarcodeResult(context: ActionContext<DataState, RootState>, data: any) {
        context.commit('updateScanProductBarcodeResult', data)
    },

    setScanUserBarcodeResult(context: ActionContext<DataState, RootState>, data: any) {
        context.commit('updateScanUserBarcodeResult', data)
    },

    setScanOrderTotalAdditionalProductBarcodeResult(context: ActionContext<DataState, RootState>, data: any) {
        context.commit('updateScanOrderTotalAdditionalProductBarcodeResult', data)
    }
}

const DataModule: Module<DataState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default DataModule;