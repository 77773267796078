/* eslint-disable no-inner-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
import XHRInterface from './interfaces/XHRInterface';
import _Vue from 'vue';
import axios from 'axios'


const apiEndpoint = process.env.NODE_ENV === 'production' ? 'https://new.amazingjackie.com/backend/request.php' : 'https://new.amazingjackie.com/backend_uat/request.php'

export function XHR(Vue: typeof _Vue): void {
    Vue.prototype.$XHR = {
        // POST API request
        post(payload: any, uploadProgress = null) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData()

                for (const e in payload) {
                    if (Object.prototype.hasOwnProperty.call(payload, e)) {
                        bodyFormData.append(e, payload[e])
                    }
                }

                const config: any = {
                    method: 'post',
                    url: apiEndpoint,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                }

                if (uploadProgress != null) {
                    config["onUploadProgress"] = uploadProgress
                }

                axios(config).then(response => {
                    // handle server response

                    if (response.data !== undefined && response.data.result !== undefined) {
                        if (response.data.result === 'success') {
                            resolve(response.data)
                        } else {
                            console.error('[API] POST failed (non-successful result)')
                            reject(response.data)
                        }
                    } else if (response.data !== undefined && Array.isArray(response.data)) {
                        if (response.data[0]) {
                            if (response.data[1].status === 'file uploaded and optimized') {
                                resolve(response.data[1].filename)
                            } else {
                                console.error('[API] POST failed (non-successful result)')
                                reject(response.data)
                            }
                        } else {
                            console.error('[API] POST failed (non-successful result)')
                            reject(response.data)
                        }
                    } else {
                        console.error('[API] POST failed (missing data or result)')
                        reject(null)
                    }

                }).catch(response => {
                    // handle error
                    console.error(response)
                    console.error('[API] POST failed (connection exception)')
                    reject(null)
                })
            })
        },

        async api(key: string, passObj: Object, uploadProgress = null, cancelControllerSignal = null) {
            const params = new URLSearchParams()
            params.set(key, JSON.stringify(passObj))


            const config: any = {}

            if (uploadProgress != undefined && uploadProgress != null) {
                config["onUploadProgress"] = uploadProgress
            }

            if (cancelControllerSignal != null) {
                config["signal"] = cancelControllerSignal
            }

            const response = await axios.post(apiEndpoint, params.toString(), config)
            const result = response.data
            if (result.result === 'success') {
                if (Object.keys(result).length > 2) {
                    const data = result;

                    delete data.result;
                    return Promise.resolve(data)
                }

                return Promise.resolve(result.data)
            }

            return Promise.reject(result)
        },
    };
}

declare module 'vue/types/vue' {
    interface Vue {
        $XHR: XHRInterface;
    }
}
